import React from 'react'
import Order from './Order'

const Orders = ({ 
  getOrders,
  orders, 
  setShowModal, 
  setModalImg,
  ordersToFulfill,
  setOrdersToFulfill,
  activeBranch
}) => {

  return (
    <div style={{
      padding: '0px 30px',
      paddingBottom: 30
    }}>
      {orders.map((order, i) => (
        <Order 
          key={i}
          getOrders={getOrders}
          order={order}
          setShowModal={setShowModal}
          ordersToFulfill={ordersToFulfill}
          setOrdersToFulfill={setOrdersToFulfill}
          setModalImg={setModalImg}
          activeBranch={activeBranch}
        />
      ))}
    </div>
  )
}

export default React.memo(Orders)