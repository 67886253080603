import React, { useEffect } from 'react'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Feed from './pages/Feed';
import Auth from './pages/Auth';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Auth />} />
        <Route path='/feed' element={<Feed />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
