import React, { useEffect, useState } from 'react'

import './header.css'

import { auth } from '../../../firebase'

const Header = ({ toggleModal }) => {

  const [ email, setEmail ] = useState('')

  const get_email = () => {
    const user = auth.currentUser
    
    if (user) {
      setEmail(user.email)
    }
  }

  useEffect(() => {
    get_email()
  }, [])
  
  return (
    <div className='container bg-white'>
      <div className='container-left'>
        <img src='/images/logoIcon.png' />
        <p>External Fulfillments</p>
      </div>

      <div
        className='container-right'
        onClick={() => {
          toggleModal()
        }}
      >
        <img src='/images/verticalLine.png' className='line-icon' />
        <p>{email}</p>
        <img src='/images/arrowDown.png' className='arrow-icon' />
      </div>
    </div>
  )
}

export default Header