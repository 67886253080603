import axios from 'axios'

const storefrontApi = axios.create({
  baseURL: 'https://tryp.shop/api/2022-07/graphql.json',
  headers: {
    "X-Shopify-Storefront-Access-Token": `54dc78a658fa04ac5ead5b05503d9eef`,
    "Content-Type": "application/graphql",
    Accept: "application/json"
  }
})

export default storefrontApi