import React, { useEffect, useState } from 'react'
import './settings.css'

const Settings = (props) => {

  const { activeBranch, setActiveBranch } = props

  const branch_offices = [
    {
      name: 'Guadalajara',
      id: '68157014270'
    },
    {
      name: 'Monterrey',
      id: '71650574590'
    },
  ]

  const toggle_branch = (id) => {
    setActiveBranch(id)
  }

  return (
    <div className='settings-container'>
      <h1>Sucursal</h1>
      <div className='buttons'>
        {branch_offices.map((item, i) => (
          <button
            key={i}
            className={`${activeBranch === item.id ? 'active-option-btn' : 'inactive-option-btn'}`}
            onClick={() => toggle_branch(item.id)}
          >
            {item.name}
          </button>
        ))}
      </div>
    </div>
  )
}

export default Settings