import React, { useEffect } from 'react'

import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth'

import { useNavigate } from 'react-router-dom';

import Login from '../components/Auth/Login'

const Auth = () => {

  const navigate = useNavigate()

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/feed')
      }
    })
  }, [])


  return (
    <div style={{
      backgroundColor: '#F1F1F1',
      height: '100%',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Login />
    </div>
  )
}

export default Auth