import React, { useEffect, useState } from 'react'

import storefrontApi from '../../../apis/storefrontApi'

const Row = ({ line, order, setShowModal, setModalImg }) => {

  const [ imgUrl, setImgUrl ] = useState('')

  const { title, variant_title, quantity, sku, product_id, variant_id } = line

  const bg = order % 2 === 0 ? 'white' : '#F1F1F1'

  const product_query = `{
    product(id: "gid://shopify/Product/${product_id}") {
      id
      variants(first: 100) {
        edges {
          node {
            id
            image {
              url
            }
          }
        }
      }
    }
  }`

  useEffect(() => {
    async function getVariantImg() {
      storefrontApi({
        method: 'POST',
        data: product_query
      })
      .then(({ data }) => {
        const { product } = data.data
        const base_variant = `gid://shopify/ProductVariant/${variant_id}`
  
        const filtered_variant = product?.variants?.edges?.find(
          item => item.node.id === base_variant
        )

        if (filtered_variant) {
          setImgUrl(filtered_variant.node.image.url)
        }
      })
    }

    getVariantImg()
  }, [])

  return (
    <tr style={{
      backgroundColor: bg,
      display: title === 'Tip' ? 'none' : ''
    }}>
      <td style={{ maxWidth: '180px', minWidth: '100px' }}>
        {title}
      </td>
      <td>{variant_title}</td>
      <td>{quantity}</td>
      <td>{sku}</td>
      <td>
        {imgUrl.length > 0 && (
          <img
            alt='product-img'
            src={imgUrl}
            onClick={() => {
              setShowModal(true)
              setModalImg(imgUrl)
            }}
            style={{
              cursor: 'pointer',
              width: '40px',
              height: '40px'
            }}
          />
        )}
      </td>
    </tr>
  )
}

export default Row