import React from 'react'

import './imageModal.css'

const ImageModal = ({ img, setShowModal }) => {

  const close_modal = () => {
    setShowModal(false)
  }

  return (
    <div className='image-modal-container'>
      <img
        className='product-img' 
        src={img} 
      />
      <img 
        className='close-img'
        src='/images/closeIcon.png'
        onClick={close_modal}
      />
    </div>
  )
}

export default ImageModal