export const parseDateToMx = (dateToFormat) => {
  const dateString = dateToFormat.toLocaleString('es-MX', {
    timeZone: 'America/Mexico_City'
  })
  
  const [dateStr, timeStr] = dateString.split(' ');
  
  const [day, month, year] = dateStr.split('/');
  const [hours, minutes, seconds] = timeStr.split(':');
  
  const date = new Date(
    +year.replace(',', ''),
    +month - 1,
    +day,
    +hours,
    +minutes,
    +seconds
  );
  
  return date;
}

export const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber);
    
  return date.toLocaleString('es', {
    month: 'long'
  })
}