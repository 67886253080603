import React, { useState, useEffect } from 'react'

import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom'

import Header from '../components/Feed/Header/Header'
import Main from '../components/Feed/Main/Main'
import Modal from '../components/Feed/Main/Modal'

const Feed = () => {

  const navigate = useNavigate()

  const [ showModal, setShowModal ] = useState(false)
  const [ showTimer, setShowTimer ] = useState(false)
  const [ mins, setMins ] = useState(10)
  const [ secs, setSecs ] = useState(0)

  const reset_timer = () => {
    setMins(10)
    setSecs(0)
  }

  useEffect(() => {
    async function check_logged_user() {
      const user = auth.currentUser

      if (!user) {
        navigate('/')
      }
    }

    check_logged_user()
  }, [])

  useEffect(() => {
    async function check_toggle() {
      const is_active = JSON.parse(localStorage.getItem('toggle'))

      if (is_active) {
        setShowTimer(true)
      }
    }

    check_toggle()
  }, [])

  const toggle_modal = () => {
    setShowModal(!showModal)
  }

  return (
    <div>
      {showModal && (
        <Modal 
          setShowTimer={setShowTimer}
          resetTimer={reset_timer}
        />
      )}
      <Header 
        toggleModal={toggle_modal}
      />
      <Main 
        showTimer={showTimer}
        mins={mins}
        secs={secs}
        setMins={setMins}
        setSecs={setSecs}
      />
    </div>
  )
}

export default Feed