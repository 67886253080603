import React, { useState } from 'react'

import { auth } from '../../firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

import toast, {  Toaster } from 'react-hot-toast'

import './login.css'

const Login = () => {

  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')

  const navigate = useNavigate()

  const handle_login = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        toast.success('Has ingresado exitosamente', {
          duration: 3000,
          style: {
            fontSize: 16,
            fontFamily: 'AvenirRoman'
          }
        })

        navigate('/feed')
      })
      .catch((error) => {
        toast.error('Credenciales invalidas', {
          duration: 3000,
          style: {
            fontSize: 16,
            fontFamily: 'AvenirRoman'
          }
        })
      })
  }

  return (
    <div className='login-container'>
      <Toaster />
      <img src='/images/logoIcon.png' />
      <p>Inicia sesión</p>

      <input 
        placeholder='Email'
        type='email'
        value={email}
        onChange={({ target }) => setEmail(target.value)}
      />
      <input 
        placeholder='Password'
        type='password'
        value={password}
        onChange={({ target }) => setPassword(target.value)}
      />
      <button onClick={handle_login}>
        Iniciar
      </button>
    </div>
  )
}

export default Login