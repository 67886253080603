import React, { useEffect, useState } from "react";

import "./order.css";
import Row from "./Row";
import Spinner from "../../Loading/Spinner";

import toast, { Toaster } from "react-hot-toast";
import adminApi from "../../../apis/adminApi";
import Geocode from "react-geocode";

import { getMonthName, parseDateToMx } from '../../../utils/getMxDate'

const Order = ({
  order,
  getOrders,
  setShowModal,
  setModalImg,
  ordersToFulfill,
  setOrdersToFulfill,
  activeBranch,
}) => {
  Geocode.setApiKey("AIzaSyC1kRpoKickZWK6Bg4h3FY56i2YNKfnF5k");
  Geocode.setLanguage("es");

  const [rows, setRows] = useState([]);
  const [articles, setArticles] = useState(0);
  const [tracingNum, setTracingNum] = useState("");
  const [trackingUrl, setTrackingUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const is_fulfill_disabled =
    tracingNum.length < 3 ||
    trackingUrl.length < 3 ||
    isLoading ||
    order.fulfillment.status === "on_hold";

  const filter_lines = () => {
    const filtered_lines = [];

    order.line_items.map((line) => {
      const search_item = order.fulfillment.line_items.find(
        (item) => item.variant_id === line.variant_id
      );

      if (search_item) {
        filtered_lines.push({
          ...line,
          order_key: search_item.id
        });

        if (line.title != 'Tip') {
          setArticles((prev) => prev + line.quantity);
        }
      }
    });

    setRows(filtered_lines);
  };

  const fulfill_order = (e) => {
    setIsLoading(true);

    const clean_lines = rows.filter((row) => row.title != "error%&");

    const items = clean_lines.map((line) => {
      console.log('line', line)
      return {
        id: line.order_key,
        quantity: line.quantity
      };
    });

    const fulfillment_object = {
      order_id: order.fulfillment.id,
      location_id: activeBranch,
      tracking_number: tracingNum,
      tracking_urls: trackingUrl,
      line_items: items,
      notify_customer: true,
    };

    adminApi({
      method: "POST",
      url: "/order/fulfill",
      data: fulfillment_object,
    })
      .then(({ data }) => {
        console.log("finished", data.data.fulfillment);

        if (data.data?.fulfillment?.status === "success") {
          show_success_toast();
          getOrders();
        } else {
          show_error_toast();
        }
      })
      .catch((e) => {
        show_error_toast();
        setIsLoading(false)
        console.log(e);
      });
  };

  const show_success_toast = () => {
    toast.success("Orden fulfilled con exito!", {
      duration: 4000,
      style: {
        fontSize: 16,
        fontFamily: "AvenirRoman",
      },
    });
  };

  const show_error_toast = () => {
    toast.error("Se produjo un error", {
      duration: 4000,
      style: {
        fontSize: 16,
        fontFamily: "AvenirRoman",
      },
    });
  };

  useEffect(() => {
    filter_lines();
  }, []);

  useEffect(() => {
    const clean_lines = rows?.filter((row) => row.title != "error%&");

    const items = clean_lines?.map((line) => {
      return {
        id: line.order_key,
        quantity: line.quantity
      };
    });

    const fulfillment_object = {
      order_id: order.fulfillment.id,
      location_id: activeBranch,
      tracking_number: tracingNum,
      tracking_urls: trackingUrl,
      line_items: items,
      notify_customer: true,
    };

    //search fulfillment
    const exists_fulfillment = ordersToFulfill.find(
      (o) => o.order_id === fulfillment_object.order_id
    );

    if (!is_fulfill_disabled) {
      if (exists_fulfillment) {
        const cleaned_fulfillments = ordersToFulfill.filter(
          (o) => o.order_id != fulfillment_object.order_id
        );

        setOrdersToFulfill(cleaned_fulfillments);

        setOrdersToFulfill((oldArray) => [...oldArray, fulfillment_object]);
      } else {
        setOrdersToFulfill((oldArray) => [...oldArray, fulfillment_object]);
      }
    } else {
      if (exists_fulfillment) {
        const cleaned_fulfillments = ordersToFulfill.filter(
          (o) => o.order_id != fulfillment_object.order_id
        );

        setOrdersToFulfill(cleaned_fulfillments);
      }
    }
  }, [tracingNum, trackingUrl]);

  const order_date = new Date(order.created_at);
  const formatted = parseDateToMx(order_date)

  const full_date = `${formatted.getDate()} ${getMonthName(formatted.getMonth())} ${formatted.getFullYear()}`

  const open_maps = () => {
    const formated_address =
      order.shipping_address?.address1 +
      " " +
      order.shipping_address?.zip +
      " " +
      order.shipping_address.province +
      " Mexico";

    Geocode.fromAddress(formated_address)
      .then((res) => {
        const { lat, lng } = res.results[0]?.geometry?.location;
        const maps_url = "https://maps.google.com?q=" + lat + "," + lng;

        window.open(maps_url, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="order-container bg-white">
      <Toaster />
      <div className="info-container">
        <div className="info-container-address">
          <h3>Dirección</h3>
          <div className="info-container-address-name">
            <h2>{order.shipping_address.name}</h2>
          </div>
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <p>
              Calle y número: <span>{order.shipping_address.address1}</span>
            </p>
            <p>
              Colonia: <span>{order.shipping_address.address2}</span>
            </p>
            <p>
              Referencias o interior:{" "}
              <span>{order.shipping_address.company}</span>
            </p>
            <p>
              CP: <span>{order.shipping_address.zip}</span>
            </p>
            <p>
              Ciudad o Municipio/Alcaldía:{" "}
              <span>{order.shipping_address.city}</span>
            </p>
            <p>
              Estado: <span>{order.shipping_address.province}</span>
            </p>
            <p>
              Teléfono: <span>{order.shipping_address.phone}</span>
            </p>
            <div style={{ marginTop: 10 }} onClick={open_maps}>
              <a>ver en google maps</a>
            </div>
          </div>
        </div>

        <div className="info-container-order">
          <p className="info-container-order-name">{order.name}</p>
          <p className="info-container-order-date">
            {full_date} {order_date.getHours()}:{order_date.getMinutes()}
          </p>

          {order.fulfillment.status === "on_hold" ? (
            <div className="info-container-order-status">
              <div className="orange-circle circle"></div>
              <p>En espera</p>
            </div>
          ) : (
            <div className="info-container-order-status">
              <div className="green-circle circle"></div>
              <p>Listo para enviar</p>
            </div>
          )}

          <div className="info-container-order-note">
            <p>Notas:</p>
            <h3>{order.note}</h3>
          </div>

          <div className="info-container-order-articles">
            <p>
              Cantidad de Artículos a despachar: <span>{articles}</span>
            </p>
          </div>

          <div className="info-container-order-fulfill">
            <input
              placeholder="Numero de seguimiento"
              value={tracingNum}
              onChange={({ target }) => setTracingNum(target.value)}
            />
            <input
              placeholder="URL de rastreo"
              value={trackingUrl}
              onChange={({ target }) => setTrackingUrl(target.value)}
            />
            <button
              disabled={is_fulfill_disabled}
              style={{
                backgroundColor: !is_fulfill_disabled
                  ? "var(--verdeTryp)"
                  : "gray",
              }}
              onClick={() => fulfill_order()}
            >
              {isLoading ? <Spinner /> : <p>Fulfill</p>}
            </button>
          </div>
        </div>
      </div>

      <div className="items-container">
        <table className="line-items">
          <tr
            style={{
              backgroundColor: "var(--softGrey)",
              width: "100%",
            }}
          >
            <th>Producto</th>
            <th>Variante</th>
            <th>Cantidad</th>
            <th>SKU</th>
            <th>Img</th>
          </tr>
          {rows?.map((row, i) => (
            <Row
              key={i}
              line={row}
              order={i}
              setShowModal={setShowModal}
              setModalImg={setModalImg}
            />
          ))}
        </table>
      </div>
    </div>
  );
};

export default React.memo(Order);
