import React, { useEffect, useState } from "react";
import "./main.css";

import Settings from "./Settings";

import adminApi from "../../../apis/adminApi";
import Loading from "../../../loadings/Loading";
import Orders from "./Orders";
import Options from "./Options";
import ImageModal from "./ImageModal";
import toast, {  Toaster } from 'react-hot-toast'


const Main = ({ showTimer, mins, secs, setMins, setSecs }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeBranch, setActiveBranch] = useState("68157014270");
  const [showModal, setShowModal] = useState(false);
  const [modalImg, setModalImg] = useState("");
  const [orders, setOrders] = useState([]);
  const [ ordersToFulfill, setOrdersToFulfill ] = useState([])

  const get_order_fulfillments = async (order) => {
    await adminApi({
      method: "GET",
      url: `/order/fulfillments/${parseInt(order.id)}`,
    }).then(({ data }) => {
      if (data?.fulfillment_orders) {
        data?.fulfillment_orders.map((f, i) => {
          if (
            f.assigned_location_id === parseInt(activeBranch) &&
            (f.status === "open" || f.status === "on_hold" || f.status === "in_progress")
          ) {
            const formated_order = {
              ...order,
              fulfillment: f,
            };

            //console.log('formated', formated_order)
            setOrders((old) => [...old, formated_order]);
          }
        });
      }
    });
  };

  const get_orders = async () => {
    setOrdersToFulfill([])
    setOrders([]);
    setIsLoading(true);

    try {
      const { data } = await adminApi({
        method: "GET",
        url: "/orders",
      });
  

      if (data.orders) {
        await Promise.all(
          await data.orders.map(async (order) => {
            const orderTags = order?.tags || '';


            if (
                orderTags.includes('VERIFICADO') &&
                !orderTags.includes('FRAUD CHECK')
              ) {
              await get_order_fulfillments(order);
            }
          })
        )
  
        setIsLoading(false)
      } else {
        console.log(data);
      }
    } catch(e) {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    get_orders();
  }, [activeBranch]);

  useEffect(() => {
    const should_recalculate = JSON.parse(localStorage.getItem("toggle"));

    const interval = setInterval(() => {
      if (secs > 0) {
        setSecs(secs - 1);
      }
      if (secs === 0) {
        if (mins === 0) {
          if (should_recalculate) {
            get_orders();
          }

          clearInterval(interval);
          setMins(10);
        } else {
          setMins(mins - 1);
          setSecs(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [mins, secs]);

  const fulfill_multiple_orders = async () => {
    if (ordersToFulfill.length === 0) {
      toast.error('No tienes ordenes para fulfillear', {
        duration: 3000,
        style: {
          fontSize: 16,
          fontFamily: 'AvenirRoman'
        }
      })

      return;
    }

    await Promise.all(
      await ordersToFulfill.map(async (order) => {
        console.log('order', order)
  
        await adminApi({
          method: "POST",
          url: "/order/fulfill",
          data: order,
        })
        .then(({ data }) => {
          console.log(data.data.fulfillment)
          if (data.data?.fulfillment?.status === "success") {
            toast.success(`Orden ${order.order_id} fue fulfilled con exito!`, {
              duration: 3000,
              style: {
                fontSize: 16,
                fontFamily: 'AvenirRoman'
              }
            })
          }
        })
        .catch((e) => {
          console.log(e)
        })
      })
    )

    console.log('finished', 'get orders again')

    setTimeout(() => {
      get_orders()
    }, 2000)
  }

  return (
    <div className="main-container">
      <Toaster />
      {showModal && <ImageModal img={modalImg} setShowModal={setShowModal} />}
      <div className="main-container-header">
        <Settings
          activeBranch={activeBranch}
          setActiveBranch={setActiveBranch}
        />
        <Options
          mins={mins}
          secs={secs}
          showTimer={showTimer}
          fulfillAll={fulfill_multiple_orders}
          recalculateOrders={get_orders}
        />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <Orders
          getOrders={get_orders}
          orders={orders}
          setShowModal={setShowModal}
          setModalImg={setModalImg}
          ordersToFulfill={ordersToFulfill}
          setOrdersToFulfill={setOrdersToFulfill}
          activeBranch={activeBranch}
        />
      )}
    </div>
  );
};

export default Main;
