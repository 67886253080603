import React from 'react'

import './options.css'

const Options = ({ 
  mins, 
  secs, 
  showTimer,
  fulfillAll,
  recalculateOrders
}) => {
  return (
    <div className='options-container'>
      <div className='options-container-refresh'>
        <div 
          style={{ display: 'flex', alignItems: 'center', gap:'5px'}}
          onClick={recalculateOrders}
        >
          <p>Actualizar</p>
          <img src='/images/updateIcon.png' />
        </div>

        {showTimer && (
          <div style={{ paddingRight: '3px'}}>
            <p>{mins}:{secs < 10 ? `0${secs}` : secs}</p>
          </div>
        )}
      </div>

      <div className='options-container-fulfill'>
        <button onClick={fulfillAll}>
          Fulfill All
        </button>
      </div>
    </div>
  )
}

export default Options