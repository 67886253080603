import React from 'react'

import Lottie from 'react-lottie'
import * as animationData from './loading.json'

const Loading = () => {

  const defaultOptions = {
    loop: true,
    animationData: animationData,
    autoplay: true
  }

  return (
    <div style={{
      marginTop: 50
    }}>
      <Lottie 
        options={defaultOptions}
        height={50}
        width={50}
      />
    </div>
  )
}

export default Loading