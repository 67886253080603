import React from 'react'

import './modal.css'
import Toggle from './Toggle'

import { auth } from '../../../firebase'
import { signOut } from 'firebase/auth'

import { useNavigate } from 'react-router-dom'

const Modal = ({ setShowTimer, resetTimer }) => {

  const navigate = useNavigate()

  const logout = () => {
    signOut(auth).then(() => {
      navigate('/')
    })
  }

  return (
    <div 
      className='modal-container bg-white'
    >
      <div className='modal-container-time'>
        <p>Actualizar automaticamente cada 10 min</p>
        <Toggle 
          setShowTimer={setShowTimer}
          resetTimer={resetTimer}
        />
      </div>

      <div 
        className='modal-container-logout'
        onClick={logout}
      >
        <p>cerrar sesión</p>
      </div>
    </div>
  )
}

export default Modal