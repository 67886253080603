import React, { useEffect, useState } from 'react'
import './toggle.css'

const Toggle = ({ setShowTimer, resetTimer }) => {

  const [ activeToggle, setActiveToggle ] = useState(false)

  const change_toggle = () => {
    localStorage.setItem('toggle', !activeToggle)
    resetTimer()
    setActiveToggle(!activeToggle)
    setShowTimer(!activeToggle)
  }

  useEffect(() => {
    async function check_toggle() {
      const is_active = JSON.parse(localStorage.getItem('toggle'))

      if (is_active) {
        setActiveToggle(true)
        setShowTimer(true)
      }
    }

    check_toggle()
  }, [])

  return (
    <div>
      <label className="switch">
        <input 
          type="checkbox" 
          checked={activeToggle}
          onChange={() => console.log('change')}
          onClick={() => change_toggle()}
        />
        <span className="slider round"></span>
      </label>
    </div>
  )
}

export default Toggle