import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAxoBB63CcoiSsZqY-wR9KJxMDljTlaSL0",
  authDomain: "tryp-fulfillments-app.firebaseapp.com",
  projectId: "tryp-fulfillments-app",
  storageBucket: "tryp-fulfillments-app.appspot.com",
  messagingSenderId: "300760109046",
  appId: "1:300760109046:web:39ab7a3f4ebb7b38d781d7"
};

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)